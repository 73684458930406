
import axios from 'axios';
import { useReducer, useContext, createContext, useEffect } from 'react'
import { reducer, SET_ACTIVE_SUBSCRIPTIONS, SET_ACTIVE_YEAR } from './reducer';

export const initialState = {
    activeYear: null,
    activeSubscriptions: null,
};

const AcademyContext = createContext();

const AcademyProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        axios.get(`/?timestamp=${new Date().getTime()}`).then(({ status, data }) => {
            if (status === 200) {
                const { active_year, active_subscriptions } = data;
                dispatch({ type: SET_ACTIVE_YEAR, payload: active_year });
                dispatch({ type: SET_ACTIVE_SUBSCRIPTIONS, payload: active_subscriptions });
            }
        });
    }, []);

    return <AcademyContext.Provider value={{ state, dispatch }}>{children}</AcademyContext.Provider>
}

function useAcademy() {
    const context = useContext(AcademyContext);
    if (context === undefined) {
        throw new Error('useAcademy must be used within a AcademyProvider');
    }
    return context;
}

export { AcademyProvider, useAcademy };