import styled from "styled-components";
import { Row } from "../utils";

export const Field = styled.div`
    padding-bottom: 0.2rem;
    margin-bottom: 0.2rem;
    position: relative;
    padding-bottom: 1.6rem;
`;

export const FieldError = styled.span`
    color: ${({ theme }) => theme.colors.danger};
    font-size: 0.7rem;
    font-weight: 600;
`;

export const ErrorRow = styled(Row)`
    position: absolute;
    bottom: 0;
    height: 1rem;
    margin: 0 0 0.4rem 0;
    justify-content: flex-end;
    color: ${({ theme }) => theme.colors.light};
`;

export const ErrorMessage = styled.span`
    color: ${({ theme }) => theme.colors.light};
    font-size: 0.7rem;
    font-weight: 600;
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0.4rem 0 0 0;
    padding: 1rem 0;
`;