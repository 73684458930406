import styled from "styled-components";
import { AiOutlineLoading } from 'react-icons/ai';
import { Heading, Row } from "../../components/forms";
import { spin } from "../../components/animations";
import { lightenColor } from "../../components/utils/colors";

export const Loader = styled.div`
    width: 50rem;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
`;

export const LoaderIcon = styled(AiOutlineLoading)`
    animation: ${spin} 2s linear infinite;
`

export const Wrapper = styled.div`
    width: 50rem;
    margin: 0 auto;
    min-height: 100%;
    padding-top: 2rem;
    padding-bottom: 5rem;
    box-sizing: border-box;
    position: relative;
`;

export const Header = styled.div`
    margin-bottom: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.palette[800]};
    
`;

export const StepNumber = styled.span`
    color: ${({ theme }) => theme.colors.light};
    background-color: ${({ theme }) => theme.colors.brand};
    padding: 0.1rem 0.8rem;
    border-radius: 0.3rem;
    margin-right: 0.8rem;
`;

export const StepTitle = styled(Heading.Large)`
    margin-top: 0.5rem;
    color: ${({ theme }) => theme.colors.brand};
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 900;
    letter-spacing: -1px;
`;

export const StepInfo = styled(Heading.Small)`
    color: ${({ theme }) => theme.colors.palette[500]};
    font-weight: 400;
    font-size: 0.9rem;
    text-transform: initial;
    margin-top: 0.6rem;
`;


export const ProgressBar = styled.div`
    display: flex;
    width:100%;
    height: 0.5rem;
    position: relative;
    margin-top: 1.5rem;

    &:before, &:after {
        position: absolute;
        height: 100%;
        content: '';
        border-radius: 3px;
    }

    &:before {
        content: '';
        width: 100%;
        background-color: ${({ theme }) => theme.colors.palette[900]};
    }

    &:after {
        content: '';
        width: ${({ width }) => width * 100}%;
        background-color: ${({ theme }) => theme.colors.brand};  
        transition: all 0.3s ease-out;
    }
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 2rem 0 0 0;
    padding: 0 0.8rem;
    height: 4.5rem;
    background-color: ${({ theme }) => theme.colors.palette[900]};
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
`;


export const Error = styled.span`
    display: flex;
    padding: 1rem;
    background-color: ${({ theme }) => theme.colors.danger};
    color: ${({ theme }) => theme.colors.light};
    width: 100%;
    box-sizing: border-box;
    margin: 0.4rem 0;
    border-radius: 0.3rem;
`;

export const Success = styled.span`
    display: flex;
    padding: 1rem;
    background-color: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.light};
    width: 100%;
    box-sizing: border-box;
    margin: 0.4rem 0;
    border-radius: 0.3rem;
`;