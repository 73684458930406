import { useDetectClickOutside } from 'react-detect-click-outside';
import { MdClose } from 'react-icons/md';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill } from 'react-icons/ri';
import { BiPlus, BiMinus } from 'react-icons/bi';
import { useCallback, useEffect, useRef, useState } from "react";
import { Row, Column, Input } from "../";
import { Wrapper, Value, OpenIcon, PillsContainer, ListContainer, List, Option } from "./Select.styles";
import { withTheme } from "styled-components";
import Pill from "../../Pill/Pill";

const Select = ({ theme, name, multiple = false, value, options, onChange, disabled }) => {

    const [initialized, setInitialized] = useState(false);
    const [open, setOpen] = useState(false);
    const input = useRef(null);

    useEffect(() => {
        if (!initialized && input.current) {
            input.current?.addEventListener('change', onChange);
            setInitialized(true);
        }
    }, [initialized, onChange]);

    const handleOpen = useCallback((e) => {
        e.stopPropagation();
        setOpen(!open);
    }, [open]);

    const handleChange = useCallback((evt, ownValue) => {
        let values = value !== '' ? value.split(',') : [];
        const castValue = ownValue.toString();
        const index = values.indexOf(castValue);

        if (multiple === true && index > -1) {
            values.splice(index, 1);
        } else {
            values.push(castValue);
        }

        input.current.value = values.length > 0 ? values.join(',') : '';
        input.current.dispatchEvent(new Event('change'));
    }, [multiple, value]);

    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    const onRemove = useCallback((ownValue) => {
        let values = value !== '' ? value.split(',') : [];
        const index = values.indexOf(ownValue.toString());

        if (index > - 1) {
            values.splice(index, 1);
        }

        input.current.value = values.length > 0 ? values.join(',') : '';
        input.current.dispatchEvent(new Event('change'));

        setOpen(false);
    }, [value]);

    const refClickOutside = useDetectClickOutside({
        onTriggered: onClose,
    });

    const values = (value !== '' ? value.split(',') : []);

    return <Wrapper ref={refClickOutside} opened={open}>
        <Value alignItems={'center'}>
            <Column gap={0} width={0.9}>
                {values.length > 0 && <PillsContainer>
                    {values.length > 0 && values.map(v => {
                        const option = options.filter((option) => option.value.toString() === v)?.pop();
                        return <Pill key={`pill-${name}-${option?.value}`} icon={<MdClose />} onIconClick={() => onRemove(option?.value)} label={option?.label} />
                    })
                    }
                </PillsContainer>}
            </Column>
            <Column gap={0} width={0.1} alignItems={'flex-end'} >
                <OpenIcon onClick={handleOpen}>
                    {open ? <BiMinus size={"1.2rem"} /> : <BiPlus size={"1.2rem"} />}
                </OpenIcon>
            </Column>
        </Value>
        {open &&
            <ListContainer>
                <List>
                    {options.map((option, index) =>
                        <Option selected={value.split(',').includes(option.value)} onClick={(evt) => handleChange(evt, option.value)} key={`${name}-${option.value}`}>

                            <Row>
                                <Column gap={"0.2rem"} width={0.9}>{option.label}</Column>
                                <Column gap={"0.2rem"} width={0.1} alignItems={'flex-end'}>
                                    {values.includes(option.value.toString()) ? <RiCheckboxCircleFill size={"1.2rem"} color={theme.colors.success} /> :
                                        <RiCheckboxBlankCircleLine size={"1.2rem"} />}
                                </Column>
                            </Row>
                        </Option>)}
                </List>
            </ListContainer>
        }
        <Input style={{ display: 'none' }} type="text" name={name} ref={input} />
    </Wrapper>
};

export default withTheme(Select);