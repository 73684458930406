import styled from "styled-components";
import { lightenColor } from "../../components/utils/colors";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
`;

export const LeftColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0.5;
    height: 100%;
    background-color: ${({ theme }) => lightenColor(theme.colors.brand, 10)};
`;


export const RightColumn = styled.div`
    display: flex;
    flex: 0.5;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
`;

export const LoginContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.palette[1000]};
`;

export const LoginForm = styled.div`
    display: flex;
    width: 70%;
`;


export const Subscription = styled.div`
    width: 70%;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${({ theme }) => lightenColor(theme.colors.info, 210)};
    border-radius: 0.3rem;
`;