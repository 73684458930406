import { AiOutlineLoading } from 'react-icons/ai';
import styled from "styled-components";
import { spin } from "../../components/utils/animations";
import { lightenColor } from '../utils/colors';


export const Wrapper = styled.div`
    width: 100%;
    ${({ height }) => height ? `height:${height}` : 'min-height: 100%'};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Icon = styled(AiOutlineLoading)`
    animation: ${spin} 2s linear infinite;
    color: ${({ theme }) => lightenColor(theme.colors.brand, -10)};
`