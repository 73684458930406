
import axios from 'axios';
import { useReducer, useContext, createContext, useEffect } from 'react'
import { reducer } from './reducer';

export const initialState = {
    classes: [],
};

const ClassesContext = createContext();

const ClassesProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        axios.get('classes')
            .then(({ data, status }) => status === 200 ? data : [])
            .then(classes => dispatch({ type: 'SET_CLASSES', payload: classes }));
    }, []);

    return <ClassesContext.Provider value={{ state, dispatch }}>{children}</ClassesContext.Provider>
}

function useClasses() {
    const context = useContext(ClassesContext);
    if (context === undefined) {
        throw new Error('useClasses must be used within a ClassesProvider');
    }
    return context;
}

export { ClassesProvider, useClasses };