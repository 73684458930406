import { FormattedMessage, useIntl } from "react-intl";
import Loader from "../../components/Loader/Loader";
import { Wrapper, LeftColumn, RightColumn, LoginContainer, LoginForm, Subscription } from "./Home.style";

import LoginFields from "../../static/forms/login.json";
import { Button, Column, Form, Label, Row, Spacer } from "../../components/forms";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { Pages } from "../urls";
import { useAcademy } from "../../context/Academy/Academy";
import { useTenant } from "../../context/Tenant/Tenant";
import moment from "moment";

const Home = () => {
    const intl = useIntl();
    const history = useHistory();
    const { state: academyState } = useAcademy();
    const { state: tenantState } = useTenant();
    const { activeSubscriptions } = academyState;

    const onSubscribeClick = useCallback(() => {
        history.push(Pages.SUBSCRIBE);
    }, [history]);

    if (!tenantState || !academyState) {
        return <Loader />;
    }

    const from = moment(activeSubscriptions?.start);
    const to = moment(activeSubscriptions?.end);

    return <Wrapper>
        <LeftColumn>
            <img src={tenantState.logo} alt="tenant-logo" />
        </LeftColumn>
        <RightColumn>
            <LoginContainer>
                <LoginForm>
                    <Form fields={LoginFields} onSubmit={() => { }} submitTitle={"Login"} />
                </LoginForm>
            </LoginContainer>
            <Spacer size="large" />
            {activeSubscriptions && <Subscription>
                <Row>
                    <Column alignItems={'center'}>
                        <Label variant="info">{
                            intl.formatMessage({ id: 'subscriptions:opened-from-to' }, {
                                year: activeSubscriptions?.year?.name,
                                from: from.format(tenantState.dateformat),
                                to: to.format(tenantState.dateformat)
                            })}</Label>
                    </Column>
                </Row>
                <Row>
                    <Column alignItems={'center'}>
                        <Label variant="info" small>
                            {intl.formatMessage({ id: 'subscriptions:opened-info' }, { year: activeSubscriptions?.year?.name, to: to.format(tenantState.dateformat) })}
                        </Label>
                    </Column>
                </Row>
                <Spacer />
                <Row>
                    <Column alignItems={'center'}>
                        <Button variant="info" onClick={onSubscribeClick}><FormattedMessage id={"subscriptions:opened-button-label"} /></Button>
                    </Column>
                </Row>
            </Subscription>}

        </RightColumn>
    </Wrapper>
};

export default Home;