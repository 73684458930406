import { useIntl, FormattedMessage } from "react-intl";
import { useCallback } from "react";
import { Form, Row, Column, Label, Spacer, Divider } from "../../components/forms";
import UserCard from "../../components/UserCard/UserCard";
import { useClasses } from "../../context/Classes/Classes";

import SubscriptionFields from "../../static/forms/subscription.json";

const Step2 = ({ data, onConfirmValid }) => {
    const intl = useIntl();
    const { state } = useClasses();

    const fields = [...SubscriptionFields].map((field) => {
        if (field.name === 'class') {
            field.options = state.classes.map(c => ({ value: c.id, label: c.name }));
            //field.value = classes.values.join(',');
        }
        return field;
    });

    const { user } = data;

    const onChange = useCallback((evt) => {
        if (evt.target.name === 'class') {
            if (evt.target.value === '') {
                onConfirmValid(false, {
                    classes: null
                });
            } else {
                onConfirmValid(true, {
                    classes: evt.target.value.split(',').map(v => parseInt(v)),
                })
            }
        }
    }, [onConfirmValid]);

    return <>
        <Row alignItems={'flex-start'}>
            <Column width={1} gap={0}>
                {user && <UserCard user={user} />}
            </Column>
        </Row>
        <Spacer />
        <Divider />
        <Row alignItems={'flex-start'}>
            <Column width={0.3} gap="0.8rem">
                <Label small><FormattedMessage id="subscribe:step-2:description" /></Label>
            </Column>
            <Column width={0.1}></Column>
            <Column width={0.6}>
                <Form onChange={onChange} fields={fields} submitTitle={intl.formatMessage({ id: 'subscribe:step-2:confirm-classes' })} />
            </Column>
        </Row>
    </>;
}

export default Step2;