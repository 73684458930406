import { useCallback, useState } from "react";
import { CgArrowLongDownE, CgArrowLongUpE, CgSearch } from 'react-icons/cg';
import { FormattedMessage } from "react-intl";
import {
    Wrapper,
    Header,
    HeaderRow,
    HeaderCell,
    HeaderCellContainer,
    HeaderLeftColumn,
    HeaderRightColumn,
    SearchField,
    SearchIcon,
    Body,
    Row,
    Cell,
    Pagination,
    PageLink,
} from "./Table.styles";

const Table = ({
    name = `table-${new Date().getTime().toString()}`,
    data = [],
    fields,
    orderBy,
    filterValue = null,
    widths = [],
}) => {

    const [perPage, setPerPage] = useState(15);
    const [page, setPage] = useState(1);
    const [orderField, setOrderField] = useState(orderBy);
    const [orderAsc, setOrderAsc] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchField, setSearchField] = useState(null);

    const onOrderBy = useCallback(field => {
        if (field === orderField) {
            setOrderAsc(!orderAsc);
            return;
        }
        setOrderField(field);
        setOrderAsc(true);
    }, [orderAsc, orderField]);

    const onSetSearch = useCallback((e, field) => {
        if (field !== searchField) {
            setSearchTerm('');
        }
        setSearchField(field);
    }, [searchField]);

    const onSetTerm = useCallback((e) => {
        setSearchTerm(e.target.value);
    }, []);

    return <>
        <Wrapper cellSpacing={0} cellPadding={0}>
            <Header>
                <HeaderRow height={'5rem'}>
                    {fields.map((field, i) => <HeaderCell width={widths && widths[i]}>
                        {searchField === field && <SearchField type="text" onChange={onSetTerm} value={searchTerm} />}
                    </HeaderCell>)}
                </HeaderRow>
                <HeaderRow>
                    {fields.map(field =>
                        <HeaderCell key={`${name}-th-${field}`}>
                            <HeaderCellContainer>
                                <HeaderLeftColumn>
                                    <SearchIcon onClick={(e) => onSetSearch(e, field)} />
                                    <FormattedMessage id={field} />
                                </HeaderLeftColumn>
                                <HeaderRightColumn>
                                    {/*field === orderField && (orderAsc ? <CgArrowLongDownE onClick={(e) => onOrderBy(field)} size={"1.2rem"} /> : <CgArrowLongUpE onClick={(e) => onOrderBy(field)} size={"1.2rem"} />)*/}
                                </HeaderRightColumn>
                            </HeaderCellContainer>
                        </HeaderCell>
                    )}
                </HeaderRow>
            </Header>
            <Body>
                {data
                    .filter((value, index) => index >= (page - 1) * perPage && index < (page - 1) * perPage + perPage)
                    .filter((value) => (searchField && searchTerm !== '') ? value[searchField].toString().toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 : true)
                    .sort((current, next) => {
                        const valueA = current[orderField];
                        const valueB = next[orderField]
                        const a = isNaN(valueA) ? valueA.toUpperCase() : parseInt(valueA);
                        const b = isNaN(valueA) ? valueB.toUpperCase() : parseInt(valueB);
                        return orderAsc ? ((a < b) ? -1 : (a > b) ? 1 : 0) : ((a > b) ? -1 : (a > b) ? 1 : 0);
                    })
                    .map((value, index) =>
                        <Row key={`${index}`}>
                            {fields.map((field, i) => (
                                <Cell key={`cell-${name}-${field}-${index}`}>
                                    {filterValue ? filterValue(value[field], field) : value[field]}
                                </Cell>))}
                        </Row>
                    )}
            </Body>
        </Wrapper>
        <Pagination>
            {Array.apply(null, Array(Math.ceil(data.length / perPage))).map((value, index) => <PageLink selected={page - 1 === index} onClick={() => setPage(index + 1)}>{index + 1}</PageLink>)}
        </Pagination>
    </>;
};

export default Table;