import styled from "styled-components";
import { lightenColor } from "../utils/colors";

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-items: center;
    font-weight: 800;
    text-transform: uppercase;
    border: none;
    border-radius: 1.5rem;
    padding: 0.8rem 1.6rem;
    cursor: pointer;
    font-family: ${({ theme }) => theme.fonts.main.name};

    background-color: ${({ theme, variant = 'default' }) => variant === 'default' ? theme.colors.palette[500] : theme.colors[variant]};
    color: ${({ theme, type = 'default' }) => type === 'default' ? theme.colors.palette[900] : theme.colors.light};

    &:hover {
        background-color: ${({ theme, variant = 'default' }) => lightenColor(variant === 'default' ? theme.colors.palette[400] : theme.colors[variant], 10)};
    }

    ${({ disabled }) => disabled && 'opacity:0.5;'}
`;