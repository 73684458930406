
import client from './../../client';
import { useReducer, useContext, createContext, useEffect } from 'react'
import { reducer } from './reducer';

export const initialState = {
    id: null,
};

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        /*client.get(`validate-session?timestamp=${new Date().getTime()}`).then(({ status, data }) => {
            if (status === 200) {
            }
        });*/
    }, []);

    return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>
}

function useAcademy() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useAcademy must be used within a UserProvider');
    }
    return context;
}

export { UserProvider, useAcademy };