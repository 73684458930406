export const SET_CLASSES = 'SET_CLASSES';

export const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_CLASSES:
            return {
                ...state,
                classes: payload
            };
        default: {
            throw new Error(`Unhandled action type: ${type}`)
        }

    }
}
