import { FormattedMessage, useIntl } from "react-intl";
import { Row, Column } from "../forms";
import { Wrapper, Header, FieldLabel, FieldValue, FieldsContainer, UserIcon, Name, Avatar } from "./UserCar.styles";
import moment from "moment";
import { useTenant } from "../../context/Tenant/Tenant";
const UserCard = ({ user }) => {

    const intl = useIntl();
    const { state: tenantState } = useTenant();

    return <Wrapper>
        <Header>
            <Row>
                <Column sideGap={"1rem"} alignItems={'flex-start'} justifyContent={'center'} width={0.15}>
                    <Avatar>
                        <UserIcon />
                    </Avatar>
                </Column>
                <Column width={0.85}>
                    <Row key={`user-field-name`}>
                        <Column width={1} gap={0}><FieldLabel><FormattedMessage id="name" /></FieldLabel></Column>
                    </Row>
                    <Row>
                        <Column width={1} gap={0}><Name variant="brand">{user.name}</Name></Column>
                    </Row>
                </Column>
            </Row>
        </Header>

        <FieldsContainer>
            {['address', 'dob', 'phone_number', 'email'].map((field) => {

                let value = user[field];

                switch (field) {
                    case 'dob':
                        value = moment(parseInt(value)).format(tenantState.dateformat);
                        break;
                    default:
                }

                return <Row key={`user-field-${field}`}>
                    <Column width={0.3}><FieldLabel>{intl.formatMessage({ id: field })}</FieldLabel></Column>
                    <Column width={0.7}><FieldValue>{value}</FieldValue></Column>
                </Row>
            })
            }
        </FieldsContainer>

    </Wrapper>;
};

export default UserCard;