import styled from "styled-components";
import { Row } from "../utils";

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 0.8rem;
    background-color: ${({ theme, opened }) => theme.colors.palette[opened ? 1000 : 900]};
    color: ${({ theme }) => theme.colors.palette[500]};
    border-radius: 0.3rem;
    min-height: 3.2rem;
    padding: 0 0.3rem;
    box-sizing: border-box;
    box-shadow: ${({ opened }) => opened ? '0 2px 6px rgba(0,0,0,0.1)' : 'none'};
`;

export const OpenIcon = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
    border-radius: 0.8rem;
    &:hover {
        background-color: ${({ theme }) => theme.colors.palette[900]};
    }
`;

export const Value = styled(Row)`
    margin: 0;
`;

export const ListContainer = styled.div`
    position: absolute;
    top: calc(100% + 1rem);
    left: -1px;
    width: calc(100%);
    background-color: ${({ theme }) => theme.colors.palette[1000]};
    z-index: 999;
    border-radius: 0.3rem;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
`;

export const List = styled.div`
    padding: 0;
    margin: 0;
    width: 100%;
    max-height: 10rem;
    overflow: auto;
`;

export const Option = styled.div`
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0.6rem 0.4rem;
    box-sizing: border-box;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.palette[700]};

    &:hover {
        background-color: ${({ theme }) => theme.colors.palette[900]};
        color: ${({ theme }) => theme.colors.palette[400]};
    }
`;

export const PillsContainer = styled.div`
    width: 100%;
    padding-left: 0.2rem;
    padding-bottom: 0.2rem;
`;