
import { Wrapper, Icon } from './Pill.styles';

const Pill = ({ label, onIconClick, icon }) => {
    return <Wrapper>
        {label}
        {icon && <Icon onClick={onIconClick}>{icon}</Icon>}
    </Wrapper>
}

export default Pill;