export const SET_ACTIVE_YEAR = 'SET_ACTIVE_YEAR';
export const SET_ACTIVE_SUBSCRIPTIONS = 'SET_ACTIVE_SUBSCRIPTIONS';

export const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_ACTIVE_YEAR:
            return {
                ...state,
                activeYear: payload
            };
        case SET_ACTIVE_SUBSCRIPTIONS:
            return {
                ...state,
                activeSubscriptions: payload
            };
        default: {
            throw new Error(`Unhandled action type: ${type}`)
        }

    }
}
