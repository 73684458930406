
import axios from 'axios';
import { useReducer, useContext, createContext, useEffect } from 'react'
import { reducer, SET_DATA } from './reducer';

export const initialState = null;

const TenantContext = createContext();

const TenantProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        axios.get(`/tenant?timestamp=${new Date().getTime()}`).then(({ status, data }) => {
            if (status) {
                dispatch({ type: SET_DATA, payload: data });
                document.title = data.name;
            }
        });
    }, []);

    return <TenantContext.Provider value={{ state, dispatch }}>{children}</TenantContext.Provider>
}

function useTenant() {
    const context = useContext(TenantContext);
    if (context === undefined) {
        throw new Error('useTenant must be used within a TenantProvider');
    }
    return context;
}

export { TenantProvider, useTenant };