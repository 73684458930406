import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Column, Row, Divider, Spacer } from "../../components/forms";
import UserCard from "../../components/UserCard/UserCard";
import { useClasses } from "../../context/Classes/Classes";
import { Error, Success } from "./Subscribe.styles";
import Loader from "../../components/Loader/Loader";

const Step3 = ({ data, setTitle, setInfo }) => {
    const intl = useIntl();
    const { state: classesState } = useClasses();
    const [started, setStarted] = useState(false);
    const [response, setResponse] = useState(null);

    const getValues = useCallback(() => {

        const { user, classes } = data;

        const form = new FormData();
        form.append('year', 1);
        form.append('user', user.id);
        form.append('classes', classes);

        return form;
    }, [data]);

    useEffect(() => {
        if (!started) {
            setStarted(true);

            axios.post('subscribe', getValues()).then(({ data, status }) => {
                if (status === 200) {
                    setTitle(intl.formatMessage({ id: 'subscribe:step-4:title:confirmation' }));
                    setInfo(intl.formatMessage({ id: 'subscribe:step-4:info:confirmation' }));
                }
                setResponse(data);
            });
        }
    }, [getValues, intl, setInfo, setTitle, started]);

    const { user } = data;

    const errors = response?.subscriptions.filter(item => item.error === true) || []
    const success = response?.subscriptions.filter(item => item.error === false) || [];

    return <>
        <Row alignItems={'flex-start'}>
            <Column width={1}>
                {user && <UserCard user={user} />}
            </Column>
        </Row>
        <Divider />
        <Spacer />
        {response ? <>
            {success.length > 0 && <Success> {intl.formatMessage({ id: `subscriptions:with-success` }, { classes: success.map(item => classesState.classes.find(({ id }) => id === item.data.class)?.name).join(', ') })}</Success>}
            {errors.length > 0 && <Error> {intl.formatMessage({ id: `subscriptions:with-error` }, { classes: errors.map(item => classesState.classes.find(({ id }) => id === item.data.class)?.name).join(', ') })}</Error>}
        </> : <Loader height="10rem" />}
    </>;
};

export default Step3;