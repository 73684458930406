import styled from "styled-components";
import { lightenColor } from "../utils/colors";

export const Input = styled.input`
    border: none;
    padding: 1rem;
    outline: none;
    width: 100%;
    background-color: transparent;
    border-radius: 0.3rem;
    font-family: ${({ theme }) => theme.fonts.main.name};
    color: ${({ theme }) => theme.colors.palette[600]};
    background-color: ${({ theme }) => theme.colors.palette[900]};
    box-sizing: border-box;
    font-weight: 600;
    
    &:-webkit-autofill {
        box-shadow: 0 0 0 30px white inset !important;
        background-color: ${({ theme }) => theme.colors.palette[900]};
    }

    &:focus:-webkit-autofill,
    &:focus {
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        color: ${({ theme }) => theme.colors.palette[400]};
        background-color:  ${({ theme }) => lightenColor(theme.colors.palette[1000], 0)};
    }

    &, &:autofill {
        background-color: transparent;
    }
`;