import styled from "styled-components";

export const Wrapper = styled.span`
    display: inline-flex;
    align-items: center;
    margin: 0.2rem 0.2rem 0 0;
    padding-left: 1rem;
    height: 2rem;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.palette[900]};
    color: ${({ theme }) => theme.colors.palette[500]};
    font-size: 0.8rem;
    border-radius: 1rem;
    cursor: default;
`;

export const Icon = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    margin-left: 0.4rem;
    color: ${({ theme }) => theme.colors.palette[800]};
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.danger};
        color: ${({ theme }) => theme.colors.light};
    }
`;