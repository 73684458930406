import styled from "styled-components";

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: ${({ alignItems }) => alignItems || 'center'};
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
    flex-direction: ${({ direction }) => direction || 'row'};
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${({ alignItems }) => alignItems || 'flex-start'};
    justify-content: ${({ justifyContent }) => justifyContent || 'center'};
    flex: ${({ width }) => width || '1'};
    margin: ${({ gap = `0.4rem` }) => gap} 0;
    padding: 0 ${({ sideGap = 0 }) => sideGap};
    box-sizing: border-box;
`;

const SpacerSizes = {
    small: '1rem',
    regular: '2rem',
    large: '3rem',
    extraLarge: '4rem'
};
export const Spacer = styled.div`
    height: ${({ size = 'small' }) => SpacerSizes[size]};
`;

export const Divider = styled.div`
    margin: 1rem 0;
    width: 100%;
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.palette[800]};
`;