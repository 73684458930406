import styled from "styled-components";

export const Heading = {
    ExtraSmall: styled.h5`
        text-transform: uppercase;
        font-size: 1.2rem;
        margin: 0.3rem 0;
        color: ${({ theme, variant }) => variant ? theme.colors[variant] : theme.colors.palette[400]};
    `,
    Small: styled.h4`
        text-transform: uppercase;
        font-size: 1.8rem;
        margin: 0.3rem 0;
        color: ${({ theme, variant }) => variant ? theme.colors[variant] : theme.colors.palette[400]};
    `,
    Medium: styled.h3`
        text-transform: uppercase;
        font-size: 2.2rem;
        margin: 0.3rem 0;
        color: ${({ theme, variant }) => variant ? theme.colors[variant] : theme.colors.palette[400]};
    `,
    Large: styled.h2`
        text-transform: uppercase;
        font-size: 2.6rem;
        margin: 0.3rem 0;
        color: ${({ theme, variant }) => variant ? theme.colors[variant] : theme.colors.palette[400]};
    `,
    ExtraLarge: styled.h1`
        text-transform: uppercase;
        font-size: 3rem;
        margin: 0.3rem 0;
        color: ${({ theme, variant }) => variant ? theme.colors[variant] : theme.colors.palette[400]};
    `
}