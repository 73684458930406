import styled from "styled-components";
import { FiUser } from 'react-icons/fi';
import { Heading, Label } from "../forms";


export const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    border-radius: 0.2rem;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
`;

export const Header = styled.div`
    background-color: ${({ theme }) => theme.colors.palette[900]};
    padding: 0.4rem 0;
    border-radius: 0.3rem 0.3rem 0 0;
`;

export const Avatar = styled.div`
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.palette[1000]};
`;

export const Name = styled(Heading.Small)`
    font-weight: 900;
`;

export const UserIcon = styled(FiUser)`
    color:  ${({ theme }) => theme.colors.palette[800]};
    font-size: 3rem;
`;

export const FieldsContainer = styled.div`
    padding: 1rem;
    box-sizing: border-box;
    background-color:  ${({ theme }) => theme.colors.palette[1000]};
    border-radius: 0 0 0.3rem 0.3rem;
`;

export const FieldLabel = styled(Label)`
    color: ${({ theme }) => theme.colors.palette[600]};
`;

export const FieldValue = styled(Label)`
    color: ${({ theme }) => theme.colors.palette[500]};
    font-weight: 600;
    text-transform: initial;
`;