export const SET_DATA = 'SET_DATA';

export const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_DATA:
            return {
                ...payload,
            };
        default: {
            throw new Error(`Unhandled action type: ${type}`)
        }

    }
}
