import { RiHome5Line } from 'react-icons/ri';
import { Wrapper, Toolbar, Icon } from "./TopBar.styles";
import { Pages } from "../../pages/urls";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { useTenant } from "../../context/Tenant/Tenant";

const TopBar = () => {

    const history = useHistory();
    const { state: tenant } = useTenant();

    const navigateTo = useCallback(page => {
        history.push(page);
    }, [history]);


    return <Wrapper>
        <Toolbar>
            <Icon onClick={() => navigateTo(Pages.HOME)}><RiHome5Line size="1.2rem" /></Icon>
        </Toolbar>
        {tenant && tenant.name}
    </Wrapper>;
}

export default TopBar;