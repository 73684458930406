const light = {
    colors: {
        brand: '#D7AA5A',
        palette: {
            100: '#000',
            200: '#161616',
            300: '#4a4a4a',
            400: '#5a5a5a',
            500: '#787878',
            600: '#8a8a8a',
            700: '#a2a2a2',
            800: '#dddddd',
            900: '#fafafa',
            1000: '#ffffff'
        },
        light: '#ffffff',
        dark: '#16161a',
        success: '#5eb95e',
        danger: '#dd514c',
        alert: '#f37b1d',
        warning: '#fad232',
        info: '#1f8dd6',
    },
    fonts: {
        main: {
            name: 'Nunito',
        }
    },
    sizes: {
        drawer: '20rem',
    }
};

export default light;