import moment from "moment";
import axios from "axios";
import { useEffect, useState } from "react";

import { Wrapper } from "./Subscriptions.styles";

import Table from "../../components/Table/Table";
import { Heading, Spacer } from "../../components/forms";
import { FormattedMessage } from "react-intl";

const Subscriptions = () => {
    const [initialized, setInitialized] = useState(false);
    const [data, setData] = useState(null);
    useEffect(() => {
        if (!initialized) {
            setInitialized(true);

            axios.get('subscriptions')
                .then(({ data }) => data)
                .then((data) => {
                    /*setData(data.sort((current, next) => {
                        const a = current.class.toUpperCase();
                        const b = next.class.toUpperCase();
                        return (a < b) ? -1 : (a > b) ? 1 : 0;
                    }));*/

                    setData(data);
                });
        }
    }, [initialized]);

    const onFilterValue = (value, field) => {
        switch (field) {
            case 'created_at':
                return moment(value).format('D/MM/YYYY, H:mm:ss');
            default:
                return value;
        }
    }

    return <Wrapper>
        <Spacer size="regular" />
        <Heading.Small>
            <FormattedMessage id="subscriptions" />
        </Heading.Small>
        {data && <Table
            filterValue={onFilterValue}
            data={data}
            fields={['created_at', 'class', 'name', 'email']}
            widths={['20%', '30%', '30%', '20%']}
            orderBy={'created_at'}
        />}
    </Wrapper>;
};

export default Subscriptions;