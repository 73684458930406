import { ImRadioUnchecked, ImRadioChecked2 } from 'react-icons/im';
import styled from 'styled-components';

const Wrapper = styled.span`
    cursor: pointer;
`;

const Checkbox = ({ onChange, checked }) => {
    return <Wrapper onClick={onChange}>{checked ? <ImRadioChecked2 /> : <ImRadioUnchecked />}</Wrapper>;
}

export default Checkbox;