import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  padding-top: 3rem;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.palette[1000]};
  color: ${({ theme }) => theme.colors.palette[400]};
  font-size: 0.9rem;
`;