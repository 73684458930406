import { useCallback, useEffect, useRef, useState } from "react";
import { Input, Row, Column, Label } from "../";

const DatePicker = ({ name, value, onChange }) => {
    const now = value ? new Date(value) : new Date();
    const input = useRef(null);
    const [values, setValues] = useState({
        day: now.getDate(),
        month: now.getMonth() + 1,
        year: now.getFullYear(),
    });
    const [initialized, setInitialized] = useState(null);

    useEffect(() => {
        if (!initialized && input.current) {
            input.current?.addEventListener('change', onChange);
            setInitialized(true);
        }
    }, [initialized, onChange, value]);

    useEffect(() => {
        const date = new Date(`${values.month}/${values.day}/${values.year}`);
        input.current.value = date.getTime();
        input.current.dispatchEvent(new Event('change'));
    }, [values]);

    const onChangeDate = useCallback((evt) => {
        const { target } = evt;

        const min = parseInt(target.getAttribute('min'));
        const max = parseInt(target.getAttribute('max'));
        const name = target.getAttribute('name');

        let val = parseInt(target.value);

        if (val < min) val = min;
        if (val > max) val = max;

        setValues({
            ...values,
            [name]: val,
        });

    }, [values]);

    return <>
        <Row>
            <Column width={0.3}><Label small>Dia</Label></Column>
            <Column width={0.3} sideGap={"0.6rem"}><Label small>Mês</Label></Column>
            <Column width={0.4}><Label small>Ano</Label></Column>
        </Row>
        <Row>
            <Column width={0.3} >
                <Input value={values.day} onChange={onChangeDate} name="day" type="number" min="1" max="31" />
            </Column>
            <Column width={0.3} sideGap={"0.6rem"}>
                <Input value={values.month} onChange={onChangeDate} name="month" type="number" min="1" max="12" />
            </Column>
            <Column width={0.4}>
                <Input value={values.year} onChange={onChangeDate} name="year" type="number" min="1900" max="2020" />
            </Column>
        </Row>
        <Input style={{ display: 'none' }} type="text" name={name} ref={input} />
    </>
};

export default DatePicker;