import axios from "axios";
import { useIntl } from "react-intl";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Checkbox, Column, Divider, Form, Label, Row, Spacer, Button } from "../../components/forms";
import Loader from "../../components/Loader/Loader";
import { Error } from "./Subscribe.styles";

import CreateUserFields from "../../static/forms/create-user.json";
import GetUserFields from "../../static/forms/get-user.json";
import UserCard from "../../components/UserCard/UserCard";

const Step1 = ({ onConfirmValid }) => {
    const intl = useIntl();
    const [option, setOption] = useState(null);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const onChangeOption = useCallback(selectedOption => {
        if (option === selectedOption) {
            return;
        }
        setUser(null);
        onConfirmValid(false, { user: null });
        setOption(selectedOption);
    }, [onConfirmValid, option]);

    const onSubmitCreateUser = useCallback((values) => {
        setError(null);

        var data = Object.keys(values).reduce((acc, key) => { acc.append(key, values[key]); return acc; }, new FormData());

        axios.post('user', data).then(({ status, data }) => {

            if (status !== 200) {
                onConfirmValid(false, { user: null });
                setUser(null);
                return;
            }
            if (data.error) {
                setError(data.code);
                setTimeout(() => {
                    setError(null);
                }, 1000);
                return;
            }
            onConfirmValid(true, { user: { ...data } });
            setUser(data);
        });
    }, [onConfirmValid]);

    const onSubmitGetUser = useCallback((values) => {

        setLoading(true);

        axios.get('user', {
            params: { ...values },
        })
            .then(({ status, data }) => ({ status, data }))
            .then(({ status, data }) => {

                if (data.error === true) {
                    setError(data.code);
                    setUser(null);
                    setTimeout(() => {
                        setError(null);
                    }, 3000);
                    setLoading(false);
                    return;
                }

                onConfirmValid(true, { user: { ...data } });
                setError(null);
                setUser(data);

                setTimeout(() => {
                    setLoading(false);
                }, 500);
            });
    }, [onConfirmValid]);

    const onChooseAnother = useCallback(() => {
        onConfirmValid(true, { user: null });
        setUser(null);
    }, [onConfirmValid]);

    if (loading) {
        return <Loader height={"10rem"} />
    }

    return <>

        {user ? <Row>
            <Column width={1} gap={0}>
                <UserCard user={user} />
                <Spacer size="regular" />
                <Row>
                    <Column><Button variant="info" onClick={onChooseAnother}>Escolher outro</Button></Column>
                </Row>
            </Column>
        </Row> :
            <>
                <Row alignItems={'flex-start'}>
                    <Column width={0.1} alignItems={'center'}>
                        <Checkbox onChange={() => onChangeOption('existing_user')} checked={option === 'existing_user'} />
                    </Column>
                    <Column width={0.4} gap={0}>
                        <FormattedMessage id="subscribe:existing-user" />
                    </Column>
                    <Column width={0.1} alignItems={'center'}>
                        <Checkbox onChange={() => onChangeOption('new_user')} checked={option === 'new_user'} />
                    </Column>
                    <Column width={0.4} gap={0}>
                        <FormattedMessage id="subscribe:new-user" />
                    </Column>
                </Row>
                <Spacer />
                <Divider />
                {option === 'existing_user' &&
                    <Row alignItems='flex-start'>
                        <Column width={0.5} sideGap="1rem" gap="0.8rem">
                            <Label small><FormattedMessage id="user:get-info" /></Label>
                        </Column>
                        <Column width={0.5} sideGap="1rem" >
                            <Form fields={GetUserFields} onSubmit={onSubmitGetUser} submitTitle={intl.formatMessage({ id: 'user:get' })} />
                        </Column>
                    </Row>}
                {option === 'new_user' &&
                    <Row alignItems='flex-start'>
                        <Column width={0.5} sideGap="1rem" gap="0.8rem">
                            <Label small><FormattedMessage id="user:form-info" /></Label>
                        </Column>
                        <Column width={0.5} sideGap="1rem" >
                            <Form fields={CreateUserFields} onSubmit={onSubmitCreateUser} submitTitle={intl.formatMessage({ id: 'user:create' })} />
                        </Column>
                    </Row>}
            </>}
        {error && <Row>
            <Column><Error><FormattedMessage id={`error:${error}`} /></Error></Column>
        </Row>}
    </>;
}

export default Step1;