export const SET_ACTIVE_YEAR = 'SET_ACTIVE_YEAR';

export const reducer = (state, { type, payload }) => {
    switch (type) {
        default: {
            throw new Error(`Unhandled action type: ${type}`)
        }

    }
}
