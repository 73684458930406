import { IntlProvider } from "react-intl";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AcademyProvider } from "./context/Academy/Academy";
import { TenantProvider } from "./context/Tenant/Tenant";
import { ClassesProvider } from "./context/Classes/Classes";

import TopBar from "./components/TopBar/TopBar";

import Subscribe from "./pages/Subscribe/Subscribe";
import Subscriptions from "./pages/Subscriptions/Subscriptions";
import Home from "./pages/Home/Home";

import light from "./themes/light";
import pt from "./locale/pt.json";

import './App.css';
import { Wrapper } from "./App.styles";
import { UserProvider } from "./context/User/User";

const App = () => {

  return (
    <IntlProvider messages={pt} locale="pt">
      <ThemeProvider theme={light}>
        <Router>
          <AcademyProvider>
            <UserProvider>
              <TenantProvider>
                <ClassesProvider>
                  <TopBar />
                  <Wrapper className="App">
                    <Switch>
                      <Route exact path="/">
                        <Home />
                      </Route>
                      <Route path="/subscribe">
                        <Subscribe />
                      </Route>
                      <Route path="/subscriptions">
                        <Subscriptions />
                      </Route>
                    </Switch>
                  </Wrapper>
                </ClassesProvider>
              </TenantProvider>
            </UserProvider>
          </AcademyProvider>
        </Router>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
