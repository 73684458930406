import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form as Former, Formik } from "formik";


import { Button, Column, DatePicker, Label, Input, Row, Select, TextArea } from "../../forms";

import { Field, ErrorRow, FieldError, Footer } from "./Form.styles";

const getFieldComponentByType = (type) => {
    switch (type) {
        case 'textarea':
            return TextArea;
        case 'select':
            return Select;
        case 'date':
            return DatePicker;
        default:
            return Input;
    }
}

const Form = ({ fields, onChange, onSubmit, submitTitle }) => {

    const intl = useIntl();

    const onValidate = useCallback(values =>
        Object.keys(values).reduce((acc, key) => {
            const { required, name, pattern = null } = fields.filter(({ name }) => name === key).pop();
            if (required === true && !values[key]) {
                acc[key] = intl.formatMessage({ id: 'required-field' }, { field: intl.formatMessage({ id: name }) });
                return acc;
            }

            if (pattern) {
                const reg = new RegExp(pattern);
                if (reg.test(values[key]) === false)
                    acc[key] = intl.formatMessage({ id: 'pattern-invalid' }, { field: intl.formatMessage({ id: name }) });
                return acc;
            }
            return acc;
        }, {})
        , [intl, fields]);

    const renderField = useCallback(({ field: { name, type, required, multiple = false, options = {} }, value, handleChange, handleBlur, error, touched, disabled }) => {

        const FieldComponent = getFieldComponentByType(type);

        const props = {};

        if (type === 'select') {
            props.options = options;
            props.multiple = multiple;
        }

        return <Field key={`field-${name}`}>
            <Row>
                <Column width={1} alignItems={'flex-start'}>
                    <Label>
                        <FormattedMessage id={name} />{required && ` *`}
                    </Label>
                </Column>
            </Row>
            <Row>
                <Column>
                    <FieldComponent
                        disabled={disabled}
                        type={type}
                        onChange={(evt) => {
                            handleChange(evt);
                            if (onChange) onChange(evt);
                        }}
                        onBlur={handleBlur}
                        name={name}
                        value={value}
                        autoComplete="off"
                        {...props}
                    />
                </Column>
            </Row>
            {touched === true && error && <ErrorRow><FieldError>{error}</FieldError>
            </ErrorRow>}
        </Field>
    }, [onChange]);

    return <Formik initialValues={fields.reduce((acc, { name, value }) => { acc[name] = value; return acc; }, {})} validate={onValidate} onSubmit={onSubmit}>
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
        }) => {
            return (<Former noValidate style={{ width: '100%' }}>
                {Object.keys(values).map((key) =>
                    renderField({
                        field: fields.filter(({ name }) => name === key).pop(),
                        value: values[key],
                        handleChange,
                        handleBlur,
                        error: errors[key] || null,
                        touched: Object.keys(touched).includes(key),
                        disabled: false,
                    })
                )}
                {onSubmit && <Footer>
                    <Button type="submit" variant="info">{submitTitle}</Button>
                </Footer>
                }
            </Former>);

        }}
    </Formik>
};

export default Form;

