import styled from "styled-components";
import { CgSearch } from 'react-icons/cg';
import { Button, Input } from "../forms";

export const Wrapper = styled.table`
    width: 100%;

`;

export const Header = styled.thead`
    margin-bottom: 1px;
`;

export const HeaderRow = styled.tr`
    height: ${({ height }) => height || '2.6rem'};
`;

export const HeaderCell = styled.th`
    font-size: 0.9rem;
    text-transform: uppercase;
    padding: 0.6rem 0.3rem;
    text-align: left;
    border-bottom: 1px solid ${({ theme }) => theme.colors.palette[800]};
`;

export const HeaderCellContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const HeaderLeftColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const HeaderRightColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const SearchField = styled(Input)`
    &:after {
        content: '';
    }
`;

export const SearchIcon = styled(CgSearch)`
    margin-right: 0.5rem;
    cursor: pointer;
`;

export const CloseSearchIcon = styled(CgSearch)`
    margin-right: 0.5rem;
`;


export const OrderIcon = styled.div``;

export const Body = styled.tbody``;

export const Row = styled.tr`
    &:nth-child(odd){
        background-color:  ${({ theme }) => theme.colors.palette[900]};
    }
    &:nth-child(even){
        background-color:  transparent;
    }
`;

export const Cell = styled.td`
    padding: 0.6rem 0.3rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.palette[900]};
    font-size: 0.8rem;
`;

export const Pagination = styled.div`
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-end;
`;

export const PageLink = styled(Button)`
    border-radius: 0.2rem;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: ${({ theme, selected }) => theme.colors.palette[selected ? 200 : 800]};
    color:  ${({ theme, selected }) => theme.colors.palette[selected ? 800 : 800]};
    background-color: ${({ theme, selected }) => selected ? theme.colors.palette[300] : 'transparent'};
    margin: 0 0.2rem;
    padding: 0;
    height: 2.4rem;
    width: 2.4rem;
    justify-content: center;
    
    &:hover {
        background-color: ${({ theme, selected }) => theme.colors.palette[selected ? 100 : 800]};
        color: ${({ theme, selected }) => theme.colors.palette[selected ? 1000 : 500]};
        border-color: ${({ theme, selected }) => theme.colors.palette[selected ? 100 : 800]};
    }
`;