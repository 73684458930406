import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";



import { useAcademy } from "../../context/Academy/Academy";

import { Heading, Row, Column, Spacer, Label } from "../../components/forms";
import { Button } from "../../components/forms";
import { Error, Wrapper, Header, StepTitle, StepInfo, StepNumber, ProgressBar, Footer, Loader, LoaderIcon } from "./Subscribe.styles";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { useHistory } from "react-router-dom";
import { Pages } from "../urls";


const StepComponents = [Step1, Step2, Step3];

const Subscribe = () => {
    const intl = useIntl();
    const history = useHistory();
    const { state: academyState } = useAcademy();
    const [step, setStep] = useState(0);
    const [stepsData, setStepsData] = useState({});
    const [stepValid, setStepValid] = useState(false);
    const [title, setTitle] = useState(intl.formatMessage({ id: `subscribe:step-${step + 1}:title` }));
    const [info, setInfo] = useState(intl.formatMessage({ id: `subscribe:step-${step + 1}:info` }));

    const handleProgress = useCallback(() => {
        const nextStep = step + 1;
        if (stepValid) {
            setTitle(intl.formatMessage({ id: `subscribe:step-${nextStep + 1}:title` }));
            setInfo(intl.formatMessage({ id: `subscribe:step-${nextStep + 1}:info` }));
            setStep(nextStep);
            setStepValid(false);
        }
    }, [intl, step, stepValid]);

    const onConfirmValidStep = useCallback((valid, data = {}) => {
        setStepsData({
            ...stepsData,
            ...data
        });
        setStepValid(valid);
    }, [stepsData]);


    if (!academyState) {
        return <Loader><LoaderIcon size="2rem" /></Loader>;
    }

    const StepComponent = StepComponents[step];
    const { activeSubscriptions } = academyState;

    return <Wrapper>
        <>
            <Header>
                <Label>
                    <FormattedMessage id="school-year-of" values={{ year: activeSubscriptions?.year.name }} />
                </Label>
                <Heading.Small>
                    <FormattedMessage id="subscription" />
                </Heading.Small>
                <Spacer />
                <ProgressBar width={(1 / StepComponents.length) * (step + 1)}></ProgressBar>
                <StepTitle><StepNumber>{`${step + 1}`}</StepNumber>{title}</StepTitle>
                <StepInfo>{info}</StepInfo>
            </Header>
            <StepComponent setInfo={setInfo} setTitle={setTitle} onConfirmValid={onConfirmValidStep} data={stepsData} />
            <Footer>
                <Row>
                    <Column>
                        {step === 0 && <Button onClick={() => history.push(Pages.HOME)}>
                            <FormattedMessage id={'back'} />
                        </Button>}
                    </Column>
                    {step !== StepComponents.length - 1 ? <Column alignItems={'flex-end'}>
                        <Button variant="success" onClick={handleProgress} disabled={!stepValid}>
                            <FormattedMessage id={'next'} />
                        </Button>
                    </Column> : <Button variant="info" onClick={() => history.push(Pages.HOME)}><FormattedMessage id={'done'} /></Button>}
                </Row>

            </Footer>
        </>
    </Wrapper>;
}


export default Subscribe;