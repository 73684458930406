import styled from "styled-components";
import { lightenColor } from "../utils/colors";

export const Wrapper = styled.div`
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    padding: 0 0.4rem;
    background-color: ${({ theme }) => theme.colors.brand};
    color:  ${({ theme }) => theme.colors.palette[900]};
    font-size: 0.8rem;
    box-sizing: border-box;
    position: fixed;
    z-index: 99;
`;

export const Toolbar = styled.div`
    margin-right: 1rem;
`;

export const Icon = styled.a`
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 0.3rem;

    &:hover {
        background-color: ${({ theme }) => lightenColor(theme.colors.brand, 10)};
    }
`;